import React from "react"
import { css } from "@emotion/core"
import ImgMain from './utils/ImgMain';

import Navegation from "./Navegation"

const header = () => {
  return (
    <header
      css={css`
        background-color: #cfcfcf; //CFCFCF BDBDBD F3F3F3
      `}
    >
      <div
        className="navbar"
        css={css`margin: auto;min-height:50px;display:flex;justify-content:space-between;align-items: center;
          @media (min-width:768px){
            width: 90%;
          }
        `}
         
      >
        <div className="logo" css={css` display:flex; align-items:center;`}>
        <div css={css`margin:0;padding:0;width:40px;height:40px;
           @media (min-width:768px){
            width:60px;height:60px
          }
        `}>
          <ImgMain></ImgMain>         
        </div>

        <h2 css={css` font-weight:300; font-size:2rem;
           @media (min-width:768px){
            font-weight:300; font-size:2.5rem;
          }
        `}>| Web Developer</h2>
        </div>
       

        <Navegation />
      </div>
    </header>
  )
}

export default header
